import React, { useEffect, useState } from 'react';
import './dataDownloadBody.sass';
import Modal from './ModaldataDownload'; // Import the Modal component
import { useSelector, useDispatch } from "react-redux";
import { downloadMonthlyData, downloadData} from "redux/slices/dataSlice";

export default function DataDownloadBody(){
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { monthListSinceJoined, trapLst, probes } = useSelector((state) => state.auth);
    const [selectedMonth, setSelectedMonth] = useState(monthListSinceJoined[0]);
    const renderDropdownOptions = (list) => {
        return list.map((item, index) => (
            <option key={index} value={item}>
                {item}
            </option>
        ));
    };
    const handleSelectChange = (event) => {
        setSelectedMonth(event.target.value);
        console.log(event.target.value);
    };
    const handleSubmit = () => {
        let payload = {
            probes : probes.probes,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            month: selectedMonth,
        }
        // Show the modal
        setShowModal(true);
        dispatch(downloadMonthlyData(payload));
    };
    return (
        <div className="account-box">
            <div className="account-container">
                <div className="account-title">
                    <h2>Monthly report</h2>   
                    <div className="account-division-container">
                        <div className="account-days-container">
                            <div className="row-container">
                             
                           </div>
                            </div>
                            <div className="right-container">
                                <div className="email-list-title-container">
                                    <div className="email-list-title"> 
                                        Choose a month to receive a report
                                    </div>
                                    <div className="row-container">
                                        <div className="month-list-months">
                                            <select className="fixed-length-dropdown" value={selectedMonth} onChange={handleSelectChange}>
                                                {renderDropdownOptions(monthListSinceJoined)}
                                            </select>
                                        </div>     
                                        <button className="email-button" onClick={handleSubmit}>Download</button> 
                                    </div>             
                                </div>
                        </div>             
                        </div>   
                        
                </div>
                
        </div>
        <div>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                Your download has started. Please wait a moment.
            </Modal>
        </div>
    </div>
    );
}