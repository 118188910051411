/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./dashboard.sass";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTrapLst,
  fetchTrapInfo,
  fetchScrollInfo,
} from "redux/slices/dataSlice";
import { fetchUserLst } from "redux/slices/authSlice";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ChartBox from "components/box/chart-box/ChartBox";
import InfoBox from "components/box/info-box/InfoBox";
import ProfileBox from "components/box/profile-box/ProfileBox";
import DownloadDataModal from "components/modal/DownloadDataModal";
import { Info } from "@mui/icons-material";

export default function Dashboard() {

  const dispatch = useDispatch();
  const { trapLst, scrollInfo} = useSelector(
    (state) => state.data
  );
  const { filter, ascend, currTeam } = useSelector(
    (state) => state.auth
  );
  const [trapLength, setTrapLength] = useState();
  useEffect(() => {
    dispatch(fetchUserLst());
  }, []);
  //filter, ascend
  useEffect(() => {
    if (trapLst) {
      dispatch(fetchTrapInfo(trapLst[0]));
    }
  }, [trapLst]);

  const [pop, setPop] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if(location.state) {
      //console.log(location.state.value);
      if(location.state.value === true) {
        setOpen(true);
      } else {
        setOpen(false)
      }
    } else {
      //console.log("No Download Data")
    }
  });

  const [pageNum, setPageNum] = useState(20);

  const handlePageNumState = (page) => {
    setPageNum(page);
  }
  
  const [scrollInfoCount, setScrollInfoCount] = useState(0);

  // Putting fetchTraps useEffect in Dashboard
  const fetchTraps = async (page) => {
    let payload = {
      msg: {
        filter: filter,
        ascend: ascend,
        teamName: currTeam,
      },
      num: page,
    };
    //console.log("Dashboard fetchTraps called, scrollInfo: ", scrollInfo);
    let response = await dispatch(fetchScrollInfo(payload));
    setScrollInfoCount(prevCount => prevCount + 1);
    setTrapLength(response.payload.trapLength);
  };

  useEffect(() => {
    if (filter !== "" || ascend !== "") {
      //console.log("Dashboard: filter and ascend just changed, now doing fetchTraps")
      fetchTraps(pageNum);
    }
  }, [filter, ascend, currTeam]);

  return (
    <div className="dashboard" onClick = {() => pop ? setPop(false) : undefined}>
      <div className="body">
        <div className="left-block">          
          <div className="row">
            <div className="info-box">
              {<InfoBox pop={pop} setPop={setPop} pageNum={pageNum} />}
            </div>
          </div>
          <div className="row">
            <div className="chart-box">
              <ChartBox />
            </div>
            <div className = "chart-box-compress"> 
              <ProfileBox pageNum={pageNum} handlePageNumState={handlePageNumState} scrollInfoCount = {scrollInfoCount} setScrollInfoCount = {setScrollInfoCount} trapLength = {trapLength} setTrapLength = {setTrapLength}/>
            </div>
          </div>
        </div>

        <div className="right-block">
          <ProfileBox pageNum={pageNum} handlePageNumState={handlePageNumState} scrollInfoCount = {scrollInfoCount} setScrollInfoCount = {setScrollInfoCount } trapLength = {trapLength} setTrapLength = {setTrapLength}/>
        </div>

        <div className = "right-block-compress">
          <ChartBox />
        </div>
        
      </div>
      {open && <DownloadDataModal open={open} setOpen={setOpen} />}
    </div>
  );
}

/*
<div className="sm-box">
              <ControlBox
                title="Brightness"
                content={brightness > 0 ? "lvl" + brightness : "off"}
                incBtn={() => {
                  handleBrightness("inc");
                }}
                decBtn={() => {
                  handleBrightness("dec");
                }}
                applyBtn={() => {
                  handleBrightness("all");
                }}
              />
            </div>
            <div className="sm-box">
              <ControlBox
                title="Frequency"
                content={frequency + "hr"}
                incBtn={() => {
                  handleFrequency("inc");
                }}
                decBtn={() => {
                  handleFrequency("dec");
                }}
                applyBtn={() => {
                  handleFrequency("all");
                }}
              />
            </div>
*/