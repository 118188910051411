import React from 'react';
import './dataDownload.sass';
import DataDownloadBody from 'components/box/dataDownload-box/body/DataDownloadBody';


export default function DataDownload(){
    return (
        <div className="dataDownload">
        <div className="dataDownload-body">
            <div className="dataDownload-title-container">
                <h1>DataDownload</h1>
            </div>
            <div>
                <DataDownloadBody />
            </div>
        </div>
    </div>
    );
}

